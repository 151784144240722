<template>
  <div class="flex flex-grow h-200">
    <div
      class="w-1/3 flex flex-col justify-between p-4 bg-no-repeat"
      :style="{ 'background-image': url(bgImage) }"
    >
      <p class="font-bold text-l text-blue-navy">
        {{ member.firstName + " " + member.lastName }}
      </p>
      <div>
        <p class="text-sm-2 text-grey-light">Job Role</p>
        <p class="font-medium text-md-2 text-grey-dark-1">
          {{ member.jobRoleName }}
        </p>
      </div>
    </div>
    <div class="w-1/4 flex flex-col justify-between py-4 px-2">
      <div>
        <!-- <p class="text-sm-2 text-grey-light">Job Role</p>
        <p class="font-medium text-md-2 text-grey-dark-1">
          {{ member.jobRoleName }}
        </p> -->
      </div>
      <div>
        <p class="text-sm-2 text-grey-light">Start Date</p>
        <p class="font-medium text-md-2 text-grey-dark-1">
          {{ this.formatDate(member.startedSurveyAt) }}
        </p>
      </div>
    </div>
    <div class="w-1/6 flex flex-col justify-between py-4 px-2">
      <div>
        <!-- <p class="text-sm-2 text-grey-light">Group</p>
        <p class="font-medium text-md-2 text-grey-dark-1">{{ member.group }}</p> -->
      </div>
      <div>
        <p class="text-sm-2 text-grey-light">Completion Date</p>
        <p class="font-medium text-md-2 text-grey-dark-1">
          {{ this.formatDate(member.completedSurveyAt) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import bgImage from "../../assets/img/results/individual-results.svg";
import moment from "moment";

export default {
  name: "UserCard",
  props: { member: { type: Object, default: null } },
  computed: {
    bgImage() {
      return bgImage;
    },
  },
  methods: {
    url(value) {
      return "url(" + value + ")";
    },
    formatDate(date) {
      return moment(date).format("MMM D, YYYY");
    },
  },
};
</script>
